import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanLoad {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.auth.profile?.isAdmin) {
      return true;
    }

    const url = state.url.split('/').filter(Boolean);
    url.pop();

    return this.router.createUrlTree(url.length ? url : ['/']);
  }

  public canLoad(): boolean | UrlTree {
    if (this.auth.profile?.isAdmin) {
      return true;
    }

    return this.router.createUrlTree(['/']);
  }
}
