import {
  MultipleQueriesOptions,
  MultipleQueriesQuery,
  MultipleQueriesResponse,
  SearchForFacetValuesResponse,
  SearchOptions,
  SearchResponse
} from '@algolia/client-search';
import { RequestOptions } from '@algolia/transporter';
import { Injectable } from '@angular/core';
import { AlgoliaQueue, CommonAlgolia, Tenant, User } from '@remodzy/types';
import { default as algoliaSearch, SearchClient, SearchIndex } from 'algoliasearch';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { appConstants } from '../../app.constants';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { TeamsService } from './teams.service';

@Injectable()
export class AlgoliaService {
  private static readonly defaultSearchParams: RequestOptions & SearchOptions = {
    attributesToHighlight: [],
    hitsPerPage: appConstants.algolia.defaultPageSize,
    sortFacetValuesBy: 'alpha',
    typoTolerance: false
  };

  private client: SearchClient | undefined;
  private region: string | undefined;

  constructor(
    private readonly auth: AuthService,
    private readonly firestoreService: FirestoreService,
    private readonly teamsService: TeamsService
  ) {}

  public initClient(tenant: Tenant, searchApiKey: string): void {
    const config = environment.algolia.config[tenant.country];
    if (!config) {
      throw new Error(`No algolia config for tenant region ${tenant.country}`);
    }
    this.region = tenant.country;
    this.client = algoliaSearch(config.appId, searchApiKey);
  }

  public getObject<T = any>(indexName: string, objectID: string): Observable<T> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    return from(index.getObject<T>(objectID));
  }

  public getObjects<T = any>(indexName: string, objectIDs: string[]): Observable<(T | null)[]> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    return from(index.getObjects<T>(objectIDs)).pipe(map(res => res.results));
  }

  public search<T = any>(
    indexName: string,
    query: string,
    params?: RequestOptions & SearchOptions
  ): Observable<SearchResponse<T>> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    const options = { ...AlgoliaService.defaultSearchParams, ...params };
    return from(index.search<T>(query, options));
  }

  public searchMulti<T = any>(
    indexName: string,
    queries: string[],
    params?: RequestOptions & MultipleQueriesOptions
  ): Observable<MultipleQueriesResponse<T>> {
    if (!this.client) {
      throw new Error('No search index. You must call initClient first.');
    }
    const multiQuery: MultipleQueriesQuery[] = queries.map(query => {
      return {
        indexName,
        query,
        params: { ...AlgoliaService.defaultSearchParams, ...params }
      };
    });
    return from(this.client.search<T>(multiQuery));
  }

  public facetedSearch<T = any>(queries: MultipleQueriesQuery[]): Observable<MultipleQueriesResponse<T>> {
    if (!this.client) {
      throw new Error('No search index. You must call initClient first.');
    }
    return from(this.client.search<T>(queries));
  }

  public prepareMultipleQuery(indexName: string, query: string, params?: SearchOptions): MultipleQueriesQuery[] {
    const facets = params?.facets || [];
    const filters = params?.filters || '';
    const options = { ...AlgoliaService.defaultSearchParams, ...params };
    const multiQuery: MultipleQueriesQuery[] = [{ indexName, query, params: options }];

    facets
      .filter(facet => filters.includes(`${facet}:`))
      .forEach(facet => {
        // Remove filter for current facet
        const facetFilters = filters
          .split(' AND ')
          .filter(part => !part.includes(facet))
          .join(' AND ');

        // Get info only for current facet applying other filters
        multiQuery.push({
          indexName,
          query,
          params: {
            ...params,
            facets: [facet],
            filters: facetFilters,
            hitsPerPage: 0
          }
        });
      });

    return multiQuery;
  }

  public searchFacets(
    indexName: string,
    facet: string,
    query: string,
    params?: RequestOptions & SearchOptions
  ): Observable<SearchForFacetValuesResponse> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    return from(index.searchForFacetValues(facet, query, params));
  }

  public clearCache(): Observable<void> {
    if (!this.client) {
      throw new Error('You must call initClient first.');
    }
    return from(this.client.clearCache());
  }

  public getQueue$<T = CommonAlgolia>(index: string): Observable<AlgoliaQueue<T>[]> {
    return this.firestoreService.getList$<AlgoliaQueue<T>>(appConstants.firebase.collections.algoliaQueue, {
      customQuery: [
        ['indexName', '==', index],
        ['tenantRegion', '==', this.region]
      ]
    });
  }

  public getRolesFilter(): string {
    const user = this.auth.profile as User;

    if (user.isAdmin) {
      return '';
    }

    const roleFilter = user.roles
      .reduce((acc, role) => {
        acc.push(`roles:${role}`);
        return acc;
      }, [] as string[])
      .join(' OR ');

    return [roleFilter, 'noRoles:true'].join(' OR ');
  }

  public getTeamFilter(): string {
    const team = this.teamsService.getCurrentTeam();
    return team ? `teams:${team}` : '';
  }

  public appendTeamRolesFilters(filters: string): string {
    const rolesFilter = this.getRolesFilter();
    const teamFilter = this.getTeamFilter();
    return [filters, rolesFilter, teamFilter]
      .filter(Boolean)
      .map(item => `(${item})`)
      .join(' AND ');
  }

  private getIndex(index: string): SearchIndex | null {
    if (!this.client) {
      return null;
    }
    return this.client.initIndex(index);
  }
}
