export const appConstants = {
  algolia: {
    defaultPageSize: 5,
    indices: {
      devices: 'core_devices',
      forms: 'form_forms',
      formTemplates: 'form_templates',
      locations: 'core_locations',
      lookups: 'form_lookups',
      positions: 'core_positions',
      reports: 'form_reports',
      roles: 'core_roles',
      teams: 'core_teams',
      users: 'auth_users'
    },
    maxHitsPerPage: 1000
  },
  cookies: {
    appToken: 'appToken',
    expiresHours: 10,
    firebaseToken: 'firebaseToken',
    searchToken: 'searchToken'
  },
  firebase: {
    collections: {
      algoliaQueue: 'common_algolia_queue',
      categories: 'form_categories',
      commonBillingPayMethods: 'common_billing_paymethods',
      devices: 'core_devices',
      formControls: 'form_controls',
      forms: 'form_forms',
      formLookups: 'form_lookups',
      formTemplates: 'form_templates',
      industries: 'core_industries',
      locations: 'core_locations',
      messages: 'messages',
      messagingGroups: 'common_chat_groups',
      positions: 'core_positions',
      reports: 'form_reports',
      roles: 'core_roles',
      teams: 'core_teams',
      tenants: 'auth_tenants',
      users: 'auth_users'
    },
    maxBatchSize: 500,
    storage: {
      formsAssets: 'forms'
    }
  },
  l10n: {
    locale: 'en-AU',
    startOfWeek: 1,
    timeFormat: 24
  },
  userSettings: {
    cardsView: 'cardsView',
    skipVideoPreview: 'skipVideoPreview'
  }
};
