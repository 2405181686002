import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AlgoliaService } from './core/services/algolia.service';
import { AuthService } from './core/services/auth.service';
import { PermissionsService } from './core/services/permissions.service';
import { TenantsService } from './core/services/tenant.service';
import { UsersService } from './core/services/users.service';

export function appInitializer(
  authService: AuthService,
  usersService: UsersService,
  tenantService: TenantsService,
  algolia: AlgoliaService,
  permissionsService: PermissionsService,
  translocoService: TranslocoService
) {
  // Skip auth initialization for public forms
  if (window.location.pathname.startsWith('/public/')) {
    return () => Promise.resolve();
  }

  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.has('error')) {
    authService.logout('errorafterlogout=true');
    return () => Promise.reject();
  }

  if (queryParams.has('errorafterlogout')) {
    authService.login('haserror=true');
    return () => Promise.reject();
  }

  if (queryParams.has('invite')) {
    authService.login(`invited=${queryParams.get('invite')}&payload=${queryParams.get('payload')}`);
    return () => Promise.reject();
  }

  return (): Observable<void> =>
    authService.checkCookies().pipe(
      switchMap(userId => (userId ? of(userId) : authService.checkSession())),
      switchMap(userId => usersService.getUserByUserId(userId)),
      switchMap(user => {
        if (!user) {
          throw new Error('User not found');
        }
        authService.profile = user;
        translocoService.setActiveLang(user.language!);
        return permissionsService.initPermissions(user.roles);
      }),
      switchMap(() => tenantService.getCurrent()),
      map(tenant => {
        if (!tenant) {
          throw new Error('No current tenant');
        }
        algolia.initClient(tenant, authService.algoliaToken as string);
      })
    );
}
