import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import {
  FormControlBase,
  FormControlDatetime,
  FormControlDropdown,
  FormControlGroup,
  FormControlInputText,
  FormControlLocation,
  FormControlSingleChoice,
  FormControlTemplate,
  FormControlTextSection
} from '@remodzy/types';
import { Observable, of } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { hashCode } from '../utils/string';
import { FirestoreService } from './firestore.service';

export interface TrackControlOptions {
  attachment?: boolean;
  date?: boolean;
  hideLabel?: boolean;
  lookup?: boolean;
  manual?: boolean;
  multi?: boolean;
  name?: boolean;
  options?: boolean;
  question?: boolean;
  state?: boolean;
  type?: boolean;
  logic?: boolean;
}

const defaultTrackControlOptions: TrackControlOptions = {
  attachment: true,
  date: true,
  hideLabel: true,
  lookup: true,
  manual: true,
  multi: true,
  name: true,
  options: true,
  question: true,
  state: true,
  type: true
};

export interface ControlGroups {
  controls: FormControlTemplate[];
  group: FormControlGroup;
  name: string;
}

@Injectable()
export class FormControlsService {
  private controls: FormControlTemplate[] | undefined;

  constructor(private readonly firestoreService: FirestoreService) {}

  public static trackControl(
    control: FormControlBase,
    controls: FormControlBase[] = [],
    options: TrackControlOptions = defaultTrackControlOptions
  ): number {
    let str = '';

    if (options.attachment) {
      str += (control as FormControlTextSection).attachment?.name || '';
    }

    if (options.date) {
      str += (control as FormControlDatetime).showTimePicker ?? '';
      str += (control as FormControlDatetime).format ?? '';
    }

    if (options.lookup) {
      str += (control as FormControlDropdown)?.lookup?.firestoreId ?? '';
    }

    if (options.manual) {
      str += (control as FormControlLocation).manual ?? '';
    }

    if (options.multi) {
      str += (control as FormControlInputText).multi ?? '';
    }

    if (options.name) {
      str += control.controlName;
    }

    if (options.options) {
      const controlOptions = (control.controlInfo.options || []).join();
      str += controlOptions;
    }

    if (options.question) {
      str += control.question;
    }

    if (options.state) {
      str += control.state;
    }

    if (options.type) {
      str += control.controlInfo.type;
    }

    if (options.hideLabel) {
      str += (control as FormControlTextSection).hideLabel ?? '';
    }

    if (!str) {
      str = control.controlName;
    }

    if ((control as FormControlSingleChoice).logicSections && options.logic) {
      (control as FormControlSingleChoice).logicSections?.forEach(section => {
        str += section.condition + section.value;
        controls
          .filter(con => con.logicSectionId === section.id && con.parentControlName === control.controlName)
          .forEach(con => (str += FormControlsService.trackControl(con)));
      });
    }

    return hashCode(str);
  }

  public getControls(force = false): Observable<FormControlTemplate[]> {
    if (!force && this.controls) {
      return of(this.controls);
    }
    return this.firestoreService
      .getList<FormControlTemplate>(appConstants.firebase.collections.formControls, {
        orderBy: 'order',
        skipDefaultQuery: true
      })
      .pipe(tap(res => (this.controls = res)));
  }

  public getControlsByGroups(force = false): Observable<ControlGroups[]> {
    const controls: ControlGroups[] = [
      {
        controls: [],
        group: FormControlGroup.choices,
        name: translate('formQuestionSettingsModal.controlGroups.sectionAndChoice')
      },
      {
        controls: [],
        group: FormControlGroup.inputs,
        name: translate('formQuestionSettingsModal.controlGroups.inputsAndFunction')
      },
      {
        controls: [],
        group: FormControlGroup.additional,
        name: translate('formQuestionSettingsModal.controlGroups.additional')
      }
    ];

    return this.getControls(force).pipe(
      map(res => {
        res.forEach(control => {
          const group = controls.find(item => item.group === control.group);
          if (!group) {
            throw new Error(`Unknown control group ${control.group}`);
          }
          group.controls.push(control);
        });
        return controls;
      })
    );
  }

  public updateCache(): Observable<void> {
    return this.getControls(true).pipe(mapTo(void 0));
  }
}
