import { Injectable } from '@angular/core';
import { Team } from '@remodzy/types';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { mapTo, startWith, switchMap, tap } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { FirebaseCrudService } from '../../interfaces/firebase-crud-service.interface';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';

@Injectable()
export class TeamsService implements FirebaseCrudService {
  private teams: Team[] | undefined;
  private readonly teamFilter$ = new BehaviorSubject<string | null>(null);
  public readonly teamChange$ = this.teamFilter$.asObservable();
  private readonly refresh$ = new Subject<void>();

  constructor(private readonly auth: AuthService, private readonly firestoreService: FirestoreService) {}

  public getTeams(force = false): Observable<Team[]> {
    if (!force && this.teams) {
      return of(this.teams);
    }
    return this.firestoreService
      .getList<Team>(appConstants.firebase.collections.teams)
      .pipe(tap(res => (this.teams = res)));
  }

  public getTeams$(): Observable<Team[]> {
    return this.refresh$.pipe(
      startWith(null),
      switchMap(() => this.firestoreService.getList<Team>(appConstants.firebase.collections.teams, { orderBy: 'name' }))
    );
  }

  public get(teamId: string): Observable<Team | null> {
    return this.firestoreService.get(appConstants.firebase.collections.teams, teamId);
  }

  public delete(teamId: string): Observable<void> {
    return this.firestoreService
      .delete(appConstants.firebase.collections.teams, teamId)
      .pipe(tap(() => this.refresh$.next()));
  }

  public create(team: Team): Observable<string> {
    return this.firestoreService
      .create(appConstants.firebase.collections.teams, team)
      .pipe(tap(() => this.refresh$.next()));
  }

  public update(teamId: string, team: Partial<Team>, merge = true): Observable<void> {
    return this.firestoreService
      .update(appConstants.firebase.collections.teams, teamId, team, merge)
      .pipe(tap(() => this.refresh$.next()));
  }

  public updateCache(): Observable<void> {
    return this.getTeams(true).pipe(mapTo(void 0));
  }

  public getCurrentTeam(): string {
    return this.teamFilter$.value ?? (this.auth.profile!.teamId || '');
  }

  public switchTeam(teamId: string): void {
    this.teamFilter$.next(teamId);
  }
}
