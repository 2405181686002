import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ToastMessage, ToastService } from '../toast.service';

@Component({
  selector: 'rox-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  public messages$: Observable<ToastMessage[]> = this.toastService.getMessages();

  constructor(private toastService: ToastService) {}

  public removeMessage(id: number): void {
    this.toastService.removeMessage(id);
  }
}
