import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { RemodzyPermissions } from '@remodzy/types';

import { PermissionsService } from './services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanLoad {
  constructor(private readonly permissionsService: PermissionsService, private readonly router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.hasPermissions(route.data?.permission);
  }

  public canLoad(route: Route): boolean | UrlTree {
    return this.hasPermissions(route.data?.permission);
  }

  private hasPermissions(permission: RemodzyPermissions): boolean | UrlTree {
    return (
      this.permissionsService.hasPermission(permission) ||
      this.router.createUrlTree(this.permissionsService.getAllowedRoute())
    );
  }
}
