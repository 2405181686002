import { Injectable } from '@angular/core';
import { NgbToastOptions } from '@ng-bootstrap/ng-bootstrap/toast/toast-config';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ToastMessage {
  id: number;
  message: string;
  options: NgbToastOptions;
  type: 'success' | 'error';
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private id = 0;
  private toasts$ = new BehaviorSubject<ToastMessage[]>([]);
  private defaultOptions: NgbToastOptions = {
    delay: 5000
  };

  public getMessages(): Observable<ToastMessage[]> {
    return this.toasts$.asObservable();
  }

  public success(message: string, options: NgbToastOptions = {}): void {
    const data = this.toasts$.getValue();
    const item: ToastMessage = {
      id: this.id++,
      message,
      options: { ...this.defaultOptions, ...options },
      type: 'success'
    };
    data.push(item);
    this.toasts$.next(data);
    this.autoHide(item.id, item.options.delay);
  }

  public error(message: string, options: NgbToastOptions = {}): void {
    const data = this.toasts$.getValue();
    const item: ToastMessage = {
      id: this.id++,
      message,
      options: { ...this.defaultOptions, ...options },
      type: 'error'
    };
    data.push(item);
    this.toasts$.next(data);
    this.autoHide(item.id, item.options.delay);
  }

  public removeMessage(id: number): void {
    const data = this.toasts$.getValue().filter(elem => elem.id !== id);
    this.toasts$.next(data);
  }

  // While auto hide for ngb-toast doesn't work
  private autoHide(id: number, delay?: number): void {
    setTimeout(() => this.removeMessage(id), delay || this.defaultOptions.delay);
  }
}
