import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@remodzy/types';
import { from, Observable, of, Subscriber } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { appConstants } from '../../app.constants';
import { FirebaseCrudService } from '../../interfaces/firebase-crud-service.interface';
import { CustomQuery, FirestoreService } from './firestore.service';
import { TenantsService } from './tenant.service';

export interface UserLocation {
  lat: number;
  lng: number;
}

@Injectable()
export class UsersService implements FirebaseCrudService {
  private readonly url = `${environment.api.url}/core`;
  private userLocation?: UserLocation;

  constructor(
    private readonly firestoreService: FirestoreService,
    private readonly http: HttpClient,
    private readonly mapLoader: MapsAPILoader,
    private readonly tenantsService: TenantsService
  ) {}

  public static getInitials(user: User): string {
    return [user.firstName?.substring(0, 1) || '', user.lastName?.substring(0, 1) || ''].join('');
  }

  public get(userId: string): Observable<User | null> {
    return this.firestoreService.get(appConstants.firebase.collections.users, userId);
  }

  public update(userId: string, user: Partial<User>, merge = true): Observable<void> {
    return this.firestoreService.update(appConstants.firebase.collections.users, userId, user, merge);
  }

  public delete(userId: string): Observable<void> {
    return this.firestoreService.delete(appConstants.firebase.collections.users, userId);
  }

  public create(user: User): Observable<string> {
    return this.firestoreService.create(appConstants.firebase.collections.users, user);
  }

  public getList(customQuery: CustomQuery[]): Observable<User[]> {
    return this.firestoreService.getList(appConstants.firebase.collections.users, { customQuery });
  }

  public getUserByUserId(userId: string): Observable<User | null> {
    return this.firestoreService
      .getList<User>(appConstants.firebase.collections.users, {
        customQuery: [['userId', '==', userId]],
        skipDefaultQuery: true
      })
      .pipe(map(users => users[0] || null));
  }

  public getCurrentUserLocation(): Observable<UserLocation> {
    if (this.userLocation) {
      return of({ ...this.userLocation });
    }

    return new Observable((observer: Subscriber<UserLocation>) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          observer.next({ ...this.userLocation });
          observer.complete();
        },
        error => observer.error(error)
      );
    }).pipe(
      catchError(() => {
        let geoCoder: google.maps.Geocoder;
        return from(this.mapLoader.load()).pipe(
          tap(() => (geoCoder = new google.maps.Geocoder())),
          switchMap(() => this.tenantsService.getCurrentRegion()),
          switchMap(region => {
            return new Observable((observer: Subscriber<UserLocation>) => {
              geoCoder.geocode({ address: region }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  this.userLocation = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                  };
                  observer.next({ ...this.userLocation });
                } else {
                  console.error(results, status);
                  observer.next({
                    lat: 0,
                    lng: 0
                  } as UserLocation);
                }
                observer.complete();
              });
            });
          })
        );
      })
    );
  }

  public sendInvitationEmail(userId: string): Observable<void> {
    return this.http.get(`${this.url}/users/resend-invite/${userId}`).pipe(mapTo(void 0));
  }
}
