import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  CollectionReference,
  collectionSnapshots,
  deleteDoc,
  doc,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  OrderByDirection,
  Query,
  query,
  QueryConstraint,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
  WhereFilterOp,
  writeBatch
} from '@angular/fire/firestore';
import { CommonFirestore } from '@remodzy/types';
import chunk from 'lodash-es/chunk';
import { forkJoin, from, Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { filterPredicate } from '../utils/rxjs';
import { AuthService } from './auth.service';

export type CustomQuery = [string, WhereFilterOp, unknown];

export type FirestoreRecord = Partial<CommonFirestore>;

export interface GetListOptions {
  customQuery?: CustomQuery[];
  orderBy?: string;
  orderDirection?: OrderByDirection;
  skipDefaultQuery?: boolean;
}

export interface BatchRecord<T> {
  collection: string;
  item: T;
  operation: Operation;
}

export type Operation = 'create' | 'delete' | 'update';

@Injectable()
export class FirestoreService {
  private get defaultQuery(): CustomQuery {
    const tenantId = this.auth.profile?.tenantId;
    if (!tenantId) {
      throw new Error('tenantId in profile is missing');
    }
    return ['tenantId', '==', tenantId];
  }

  private get tenantId(): string {
    const tenantId = this.auth.profile?.tenantId;
    if (!tenantId) {
      throw new Error('No tenantId');
    }
    return tenantId;
  }

  constructor(private readonly auth: AuthService, private readonly db: Firestore) {}

  public static mapDocSnapshot<T extends FirestoreRecord>(docSnapshot: DocumentSnapshot<T>): T | null {
    if (!docSnapshot.exists()) {
      return null;
    }

    const doc = docSnapshot.data();
    Object.keys(doc).forEach(key => {
      const field = key as keyof T;

      // Convert Timestamp to Date (non-recursive)
      if (doc[field] instanceof Timestamp) {
        doc[field] = (doc[field] as Timestamp).toDate() as T[keyof T];
      }
    });

    return {
      id: docSnapshot.id,
      ...doc
    };
  }

  public getRandomId(collectionName: string): string {
    return doc(this.getCollectionRef(collectionName)).id;
  }

  public getList<T extends FirestoreRecord>(collectionName: string, options?: GetListOptions): Observable<T[]> {
    const collectionQuery = this.getCollectionQuery<T>(collectionName, options);
    return from(getDocs(collectionQuery)).pipe(
      map(snapshot =>
        snapshot.docs.map(docSnapshot => FirestoreService.mapDocSnapshot(docSnapshot)).filter(filterPredicate)
      )
    );
  }

  public getList$<T extends FirestoreRecord>(collectionName: string, options?: GetListOptions): Observable<T[]> {
    const collectionQuery = this.getCollectionQuery<T>(collectionName, options);
    return collectionSnapshots(collectionQuery).pipe(
      map(snapshots =>
        snapshots.map(docSnapshot => FirestoreService.mapDocSnapshot(docSnapshot)).filter(filterPredicate)
      )
    );
  }

  public get<T extends FirestoreRecord>(collectionName: string, docId: string): Observable<T | null> {
    const docRef = doc(this.getCollectionRef<T>(collectionName), docId);
    return from(getDoc(docRef)).pipe(map(docSnapshot => FirestoreService.mapDocSnapshot(docSnapshot)));
  }

  public create<T extends Partial<CommonFirestore>>(
    collectionName: string,
    data: T,
    docId?: string
  ): Observable<string> {
    const copy = this.prepareItemForCreate(data);
    if (docId) {
      const docRef = doc(this.getCollectionRef<T>(collectionName), docId);
      return from(setDoc(docRef, copy)).pipe(mapTo(docId));
    }
    return from(addDoc(this.getCollectionRef<T>(collectionName), copy)).pipe(map(ref => ref.id));
  }

  public update<T extends Partial<CommonFirestore>>(
    collectionName: string,
    docId: string,
    data: Partial<T>,
    merge = true
  ): Observable<void> {
    const copy = this.prepareItemForUpdate(data);
    const docRef = doc(this.getCollectionRef<T>(collectionName), docId);
    return from(setDoc(docRef, copy, { merge }));
  }

  public delete(collectionName: string, docId: string): Observable<void> {
    const docRef = doc(this.getCollectionRef(collectionName), docId);
    return from(deleteDoc(docRef));
  }

  public batchUpdate<T extends Partial<CommonFirestore>>(batchRecords: BatchRecord<T>[]): Observable<void> {
    const chunks = chunk<BatchRecord<T>>(batchRecords, appConstants.firebase.maxBatchSize);
    const requests = chunks.map(chuckItems => {
      const batch = writeBatch(this.db);
      chuckItems.forEach(item => {
        const docRef =
          item.operation === 'create'
            ? doc(this.getCollectionRef(item.collection))
            : doc(this.getCollectionRef(item.collection), item.item.id);
        switch (item.operation) {
          case 'create': {
            const data = this.prepareItemForCreate(item.item);
            return batch.set(docRef, data);
          }
          case 'delete': {
            return batch.delete(docRef);
          }
          case 'update': {
            const data = this.prepareItemForUpdate(item.item);
            return batch.update(docRef, data);
          }
          default:
            const exhaustiveCheck: never = item.operation;
            throw new Error(`Forgot to process operation: ${exhaustiveCheck}`);
        }
      });
      return from(batch.commit());
    });
    return forkJoin(requests).pipe(mapTo(void 0));
  }

  private getCollectionRef<T>(collectionName: string): CollectionReference<T> {
    return collection(this.db, collectionName) as CollectionReference<T>;
  }

  private getCollectionQuery<T>(collectionName: string, options?: GetListOptions): Query<T> {
    const queryConstraints: QueryConstraint[] = (options?.customQuery || []).map(([field, operation, value]) =>
      where(field, operation, value)
    );

    if (!options?.skipDefaultQuery) {
      queryConstraints.unshift(where(...this.defaultQuery));
    }

    if (options?.orderBy) {
      queryConstraints.push(orderBy(options.orderBy, options.orderDirection || 'asc'));
    }

    return query(this.getCollectionRef<T>(collectionName), ...queryConstraints);
  }

  private prepareItemForUpdate<T extends Partial<CommonFirestore>>(data: T): T {
    const { id, ...rest } = data;
    return {
      ...(rest as T),
      tenantId: this.tenantId,
      updatedAt: serverTimestamp()
    };
  }

  private prepareItemForCreate<T extends Partial<CommonFirestore>>(data: T): T {
    return {
      ...this.prepareItemForUpdate(data),
      createdAt: serverTimestamp()
    };
  }
}
