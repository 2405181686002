import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RemodzyPermissions, Team } from '@remodzy/types';
import { filter, mapTo, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from '../core/services/auth.service';
import { UnsubscribeService } from '../core/services/root-services/unsubscribe.service';
import { TeamsService } from '../core/services/teams.service';

type GroupedTeams = { [location: string]: Team[] };

interface TeamMenuLocation {
  location: string;
  teams: Team[];
}

const noHeaderRoutes = [/form-builder\/.+/, /forms\/.+/];

@Component({
  selector: 'rox-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class NavBarComponent implements OnInit {
  public currentTeam: Team | null | undefined = undefined;
  public locations: TeamMenuLocation[] = [];
  public readonly serviceUrl = environment.appUrls.service;
  public readonly permissions = RemodzyPermissions;
  public readonly isAdmin = !!this.auth.profile?.isAdmin;
  public readonly fullName = this.auth.profile?.fullName || '';
  public readonly initials = this.auth.initials;

  @HostBinding('class.hidden') private hidden = true;

  constructor(
    @Self() private readonly unsub: UnsubscribeService,
    private readonly auth: AuthService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly teamService: TeamsService
  ) {}

  public ngOnInit(): void {
    this.watchTeams();
    this.watchRouter();
  }

  public selectTeam(team: Team | null): void {
    if (this.currentTeam !== team) {
      this.currentTeam = team || null;
      this.teamService.switchTeam(team?.id || '');
    }
  }

  public logout(): void {
    this.auth.logout();
  }

  private watchTeams(): void {
    this.unsub.subs = this.teamService
      .getTeams$()
      .pipe(
        tap(teams => {
          const groupedByLocation = teams.reduce((groups, team) => {
            groups[team.location] = groups[team.location] || [];
            groups[team.location].push(team);
            return groups;
          }, {} as GroupedTeams);

          this.locations = Object.keys(groupedByLocation)
            .map(location => {
              return {
                location,
                teams: groupedByLocation[location]
              };
            })
            .sort((a, b) => a.location.localeCompare(b.location));

          if (this.currentTeam === undefined) {
            // Initialization, select default team
            this.setCurrentTeam();
            this.selectTeam(this.currentTeam || null);
            this.cdr.markForCheck();
          } else if (this.currentTeam) {
            const currentTeam = teams.find(t => t.id === this.currentTeam!.id);
            if (currentTeam) {
              // Refresh team name after team update
              this.currentTeam = currentTeam;
            } else {
              // Select default team after team deletion
              this.setCurrentTeam();
              this.selectTeam(this.currentTeam || null);
            }
            this.cdr.markForCheck();
          }
        }),
        mapTo(void 0)
      )
      .subscribe();
  }

  private watchRouter(): void {
    this.unsub.subs = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const { url } = event as NavigationEnd;
      noHeaderRoutes.some(route => {
        this.hidden = route.test(url);
        return this.hidden;
      });
    });
  }

  private setCurrentTeam(): void {
    this.locations.some(location => {
      const userTeam = location.teams.find(team => team.id === this.auth.profile!.teamId);
      if (userTeam) {
        this.currentTeam = userTeam;
        return true;
      }
      return false;
    });
  }
}
