<rox-icons></rox-icons>
<rox-toast></rox-toast>

<div class="main">
  <rox-navbar *ngIf="headerEnabled" class="top-bar"></rox-navbar>
  <div *ngIf="showLoader" class="loader-main">
    <div class="loader"></div>
  </div>
  <router-outlet (activate)="routeActivate()"></router-outlet>
</div>
