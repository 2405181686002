import { Injectable } from '@angular/core';
import { RemodzyPermissions, Role } from '@remodzy/types';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';

@Injectable()
export class PermissionsService {
  private readonly defaultAllowedRoute = ['/settings', '/profile'];

  private readonly routes = [
    { permission: RemodzyPermissions.Forms, route: ['/forms'] },
    { permission: RemodzyPermissions.Templates, route: ['/form-builder'] },
    { permission: RemodzyPermissions.Analytics, route: ['/analytics'] },
    { permission: RemodzyPermissions.Messaging, route: ['/messaging'] },
    { permission: RemodzyPermissions.Settings, route: ['/settings', '/business'] }
  ];

  private permissions: RemodzyPermissions[] = [];

  constructor(private readonly auth: AuthService, private readonly firestoreService: FirestoreService) {}

  public initPermissions(rolesIds?: string[]): Observable<void> {
    if (!rolesIds?.length) {
      this.permissions = [];
      return of(void 0);
    }

    return forkJoin(
      rolesIds.map(id => this.firestoreService.get<Role>(appConstants.firebase.collections.roles, id))
    ).pipe(
      map(roles => [...new Set(roles.map(r => r?.permissions || []).flat())]),
      tap(permissions => (this.permissions = permissions)),
      mapTo(void 0)
    );
  }

  public hasPermission(permission: RemodzyPermissions): boolean {
    return this.auth.profile?.isAdmin || this.permissions.includes(permission);
  }

  public getAllowedRoute(): string[] {
    for (const { permission, route } of this.routes) {
      if (this.permissions.includes(permission)) {
        return route;
      }
    }
    return this.defaultAllowedRoute;
  }
}
