import { Injectable } from '@angular/core';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth0Client, GenericError, IdToken, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { User } from '@remodzy/types';
import { addHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { appConstants } from '../../app.constants';
import { ApiService } from '../api/api.service';

@Injectable()
export class AuthService {
  private readonly client = new Auth0Client({
    client_id: environment.authConfig.clientID,
    domain: environment.authConfig.domain,
    redirect_uri: `${window.location.origin}`,
    useRefreshTokens: true
  });

  private get getTokenSilently$(): Observable<string> {
    return from(this.client.getTokenSilently());
  }

  private get getIdTokenClaims$(): Observable<IdToken | undefined> {
    return from(this.client.getIdTokenClaims());
  }

  private get handleRedirectCallback$(): Observable<RedirectLoginResult> {
    return from(this.client.handleRedirectCallback());
  }

  private get isAuthenticated$(): Observable<boolean> {
    return from(this.client.isAuthenticated());
  }

  private readonly idTokenSubject = new ReplaySubject<string | null>(1);
  public readonly idToken$ = this.idTokenSubject.asObservable();
  public algoliaToken: string | null = null;
  public profile: User | null = null;

  private firebaseToken: string | null = null;
  private readonly jwtHelper = new JwtHelperService();

  public get initials(): string {
    return `${this.profile?.firstName.substring(0, 1) || ''}${this.profile?.lastName.substring(0, 1) || ''}`;
  }

  constructor(
    private readonly api: ApiService,
    private readonly cookie: CookieService,
    private readonly ngFireAuth: Auth
  ) {
    this.idTokenSubject.next(null);
  }

  public checkCookies(): Observable<string> {
    const idToken = this.cookie.get(appConstants.cookies.appToken);
    if (!idToken) {
      return of('');
    }

    if (this.jwtHelper.isTokenExpired(idToken)) {
      this.clearCookies();
      return of('');
    }

    this.idTokenSubject.next(idToken);

    return of(this.cookie.get(appConstants.cookies.firebaseToken)).pipe(
      switchMap(fbToken => {
        if (!fbToken || this.jwtHelper.isTokenExpired(fbToken)) {
          return this.getFirebaseToken();
        }
        this.firebaseToken = fbToken;
        return of(void 0);
      }),
      map(() => this.cookie.get(appConstants.cookies.searchToken)),
      switchMap(algoliaToken => {
        if (!algoliaToken) {
          return this.getAlgoliaToken();
        }
        this.algoliaToken = algoliaToken;
        return of(void 0);
      }),
      switchMap(() => this.loginToFirebase())
    );
  }

  public checkSession(): Observable<string> {
    return this.isAuthenticated$.pipe(
      switchMap(isLoggedIn =>
        isLoggedIn
          ? of(void 0)
          : this.getTokenSilently$.pipe(
              catchError((e: GenericError) => {
                if (e.error === 'login_required') {
                  this.login();
                } else {
                  this.logout('errorafterlogout=true');
                }
                throw e;
              })
            )
      ),
      switchMap(() => {
        const url = new URL(window.location.href);
        if (url.searchParams.has('state')) {
          return this.handleRedirectCallback$.pipe(
            map(() => window.history.replaceState(null, '', url.origin + url.pathname))
          );
        } else {
          return of(void 0);
        }
      }),
      switchMap(() => this.getAuthToken()),
      switchMap(() => forkJoin([this.getAlgoliaToken(), this.getFirebaseToken()])),
      switchMap(() => this.loginToFirebase())
    ) as Observable<string>;
  }

  public login(query?: string): void {
    this.client.loginWithRedirect({
      redirect_uri: query ? `${window.location.origin}?${query}` : window.location.origin
    });
  }

  public logout(query?: string): void {
    this.clearCookies();
    this.client.logout({
      client_id: environment.authConfig.clientID,
      returnTo: query ? `${window.location.origin}?${query}` : window.location.origin
    });
  }

  public getNewToken(): Observable<void> {
    return this.getTokenSilently$.pipe(switchMap(() => this.getAuthToken()));
  }

  public clearToken(): void {
    this.idTokenSubject.next(null);
  }

  private getAuthToken(): Observable<void> {
    return this.getIdTokenClaims$.pipe(
      map(claims => {
        if (!claims?.__raw) {
          throw new Error(`Can't get id token`);
        }
        this.idTokenSubject.next(claims.__raw);
        this.setCookie(appConstants.cookies.appToken, claims.__raw);
      })
    );
  }

  private getAlgoliaToken(): Observable<void> {
    return this.api.core.getAlgoliaToken().pipe(
      map(token => {
        this.algoliaToken = token;
        this.setCookie(appConstants.cookies.searchToken, token);
      })
    );
  }

  private getFirebaseToken(): Observable<void> {
    return this.api.core.getFireBaseToken().pipe(
      map(token => {
        this.firebaseToken = token;
        this.setCookie(appConstants.cookies.firebaseToken, token);
      })
    );
  }

  private loginToFirebase(): Observable<string> {
    return from(signInWithCustomToken(this.ngFireAuth, this.firebaseToken!)).pipe(
      map(credentials => credentials.user.uid)
    );
  }

  private setCookie(name: string, value: string): void {
    const expires = addHours(new Date(), appConstants.cookies.expiresHours);
    this.cookie.set(name, value, expires, '/', environment.cookies.domain);
  }

  private clearCookies(): void {
    this.cookie.deleteAll('/', environment.cookies.domain);
  }
}
