import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';

import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private readonly auth: AuthService) {}

  public canActivate(): boolean {
    return this.check();
  }

  public canLoad(): boolean {
    return this.check();
  }

  private check(): boolean {
    if (this.auth?.profile) {
      return true;
    } else {
      this.auth?.login();
      return false;
    }
  }
}
