import { Injectable } from '@angular/core';
import {
  FormControlBase,
  FormControlSingleChoice,
  FormControlTemplate,
  FormControlType,
  FormTemplate
} from '@remodzy/types';
import { GeoPoint } from 'firebase/firestore';
import { Observable } from 'rxjs';

import { appConstants } from '../../app.constants';
import { formBuilderConstants } from '../../form-builder/form-builder.constants';
import { FirestoreService } from './firestore.service';

export enum FormValidationError {
  titleRequired = 'titleRequired',
  teamRequired = 'teamRequired',
  oneControlRequired = 'oneControlRequired',
  controlError = 'controlError'
}

@Injectable()
export class FormTemplatesService {
  constructor(private readonly firestoreService: FirestoreService) {}

  public static validateForm(form: FormTemplate): Error | null {
    if (!form.title) {
      return {
        name: FormValidationError.titleRequired,
        message: 'Form title is required'
      };
    }

    if (!form.teams) {
      return {
        name: FormValidationError.teamRequired,
        message: 'Form must be assigned to one team at least'
      };
    }

    if (!form.controls.length) {
      return {
        name: FormValidationError.oneControlRequired,
        message: 'Form must have one control at least'
      };
    }

    const controlsError = form.controls.some(control => {
      return !control.mappingName || !control.question;
    });

    if (controlsError) {
      return {
        name: FormValidationError.controlError,
        message: 'Some control(s) missing question or mapping name'
      };
    }

    return null;
  }

  public get(templateId: string): Observable<FormTemplate | null> {
    return this.firestoreService.get(appConstants.firebase.collections.formTemplates, templateId);
  }

  public delete(templateId: string): Observable<void> {
    return this.firestoreService.delete(appConstants.firebase.collections.formTemplates, templateId);
  }

  public update(templateId: string, template: Partial<FormTemplate>, merge = true): Observable<void> {
    return this.firestoreService.update(appConstants.firebase.collections.formTemplates, templateId, template, merge);
  }

  public create(template: FormTemplate): Observable<string> {
    return this.firestoreService.create(appConstants.firebase.collections.formTemplates, template);
  }

  public parseForm(
    data: string,
    googleGlass: boolean,
    controlTemplates: FormControlTemplate[]
  ): Partial<FormTemplate> | null {
    try {
      const formTemplate = new FormTemplate(GeoPoint, JSON.parse(data));
      delete formTemplate.id;
      formTemplate.controls = googleGlass
        ? this.adoptControlsForGlass(formTemplate.controls, controlTemplates)
        : this.adoptControls(formTemplate.controls, controlTemplates);

      return formTemplate;
    } catch (error) {
      console.error(error);
      throw new Error('Imported file has wrong format');
    }
  }

  private adoptControlsForGlass(
    controls: FormControlBase[],
    controlTemplates: FormControlTemplate[]
  ): FormControlBase[] {
    return controls
      .filter(
        control =>
          !formBuilderConstants.googleGlassDisabledControls.includes(control.controlInfo.type) &&
          !control.logicSectionId &&
          !control.isInTable
      )
      .map((control: FormControlBase) => {
        const controlTemplate = controlTemplates.find(elem => elem.type === control.controlInfo.type);

        if (!controlTemplate) {
          return null as any;
        }

        const result: FormControlBase = { ...control } as FormControlBase;

        result.logicSectionId = '';
        result.controlInfo = {
          ...controlTemplate
        };

        if (control.controlInfo.type === FormControlType.singleChoice) {
          delete (result as FormControlSingleChoice).logicSections;
          (result as FormControlSingleChoice).notify = false;
          (result as FormControlSingleChoice).notificationSettings = null;
          result.controlInfo.options = Array.isArray(control.controlInfo.options)
            ? control.controlInfo.options.slice(0, formBuilderConstants.googleGlassMaxChoiceOptions)
            : control.controlInfo.options;
        }

        if (control.controlInfo.type === FormControlType.multipleChoice) {
          result.controlInfo.options = Array.isArray(control.controlInfo.options)
            ? control.controlInfo.options.slice(0, formBuilderConstants.googleGlassMaxChoiceOptions)
            : control.controlInfo.options;
        }

        return result;
      })
      .filter(Boolean);
  }

  private adoptControls(controls: FormControlBase[], controlTemplates: FormControlTemplate[]): FormControlBase[] {
    return controls
      .map((control: FormControlBase) => {
        const controlTemplate = controlTemplates.find(elem => elem.type === control.controlInfo.type);

        if (!controlTemplate) {
          return null as any;
        }

        const result: FormControlBase = { ...control } as FormControlBase;
        result.controlInfo = {
          ...controlTemplate
        };

        if (
          control.controlInfo.type === FormControlType.singleChoice ||
          control.controlInfo.type === FormControlType.multipleChoice
        ) {
          result.controlInfo.options = control.controlInfo.options;
        }

        return result;
      })
      .filter(Boolean);
  }
}
