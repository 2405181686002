import { FormControlType } from '@remodzy/types';

export const formBuilderConstants = {
  googleGlassDisabledControls: [
    FormControlType.section,
    FormControlType.inputNumber,
    FormControlType.textSection,
    FormControlType.datetime,
    FormControlType.table,
    FormControlType.location,
    FormControlType.electronicSignature,
    FormControlType.pageBreak,
    FormControlType.attachment
  ],
  googleGlassMaxChoiceOptions: 3
};
