import { Injectable } from '@angular/core';

import { CoreService } from './core.service';
import { FormsService } from './forms.service';
import { PublicService } from './public.service';

@Injectable()
export class ApiService {
  constructor(public core: CoreService, public forms: FormsService, public publicService: PublicService) {}
}
