import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RemodzyPermissions } from '@remodzy/types';

import { AdminGuard } from './core/admin.guard';
import { AuthGuard } from './core/auth.guard';
import { PermissionsGuard } from './core/permissions.guard';

const routes: Routes = [
  {
    path: 'staff',
    loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
    canLoad: [AuthGuard, AdminGuard],
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, PermissionsGuard],
    data: {
      permission: RemodzyPermissions.Analytics
    }
  },
  {
    path: 'form-builder',
    loadChildren: () => import('./form-builder/form-builder.module').then(m => m.FormBuilderModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, PermissionsGuard],
    data: {
      permission: RemodzyPermissions.Templates
    }
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, PermissionsGuard],
    data: {
      permission: RemodzyPermissions.Forms
    }
  },
  {
    path: 'messaging',
    loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, PermissionsGuard],
    data: {
      permission: RemodzyPermissions.Messaging
    }
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: '**',
    redirectTo: 'forms'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
