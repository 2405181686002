import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoice, PowerBICreds, Report } from '@remodzy/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export interface PowerBITokenResponse {
  token: string;
  tokenId: string;
}

export interface UserResponse {
  userName: string;
  password: string;
}

export interface TwilioTokenRequest {
  room: string;
}

export interface TwilioTokenResponse {
  token: string;
}

@Injectable()
export class CoreService {
  private static readonly url = `${environment.api.url}/core`;

  constructor(private http: HttpClient) {}

  public getFireBaseToken(): Observable<string> {
    return this.http.get<{ token: string }>(`${CoreService.url}/token`).pipe(map(res => res.token));
  }

  public getAlgoliaToken(): Observable<string> {
    return this.http.get<{ token: string }>(`${CoreService.url}/search-token`).pipe(map(res => res.token));
  }

  public getDBConnectionString(): Observable<string> {
    return this.http
      .get<{ connString: string }>(`${CoreService.url}/settings/get-db-connection`)
      .pipe(map(res => res.connString));
  }

  public getAPICredentials(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${CoreService.url}/settings/get-api-creds`);
  }

  public resetPassword(password: string) {
    return this.http.post(`${CoreService.url}/settings/reset-password`, { password });
  }

  public getPowerBICreds(): Observable<PowerBICreds> {
    return this.http.post<PowerBICreds>(`${CoreService.url}/settings/pbi/getPowerBICreds`, null);
  }

  public setPowerBICreds(creds: PowerBICreds) {
    return this.http.post(`${CoreService.url}/settings/pbi/setPowerBICreds`, creds);
  }

  public getPowerBIReports(): Observable<Report[]> {
    return this.http.post<Report[]>(`${CoreService.url}/settings/pbi/getPowerBIReports`, null);
  }

  public getPowerBIReport(report: Report): Observable<PowerBITokenResponse> {
    return this.http.post<PowerBITokenResponse>(`${CoreService.url}/settings/pbi/getPowerBIEmbeddedReport`, report);
  }

  public getInvoices() {
    return this.http.post<Invoice[]>(`${CoreService.url}/settings/billing/getInvoices`, null);
  }

  public getTwilioToken(roomRequest: TwilioTokenRequest): Observable<TwilioTokenResponse> {
    return this.http.post<TwilioTokenResponse>(`${CoreService.url}/twtoken`, roomRequest);
  }
}
