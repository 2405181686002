import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApiService } from './api.service';
import { CoreService } from './core.service';
import { FormsService } from './forms.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PublicService } from './public.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ApiService,
    CoreService,
    FormsService,
    PublicService
  ]
})
export class ApiModule {}
