export const environment = {
  agm: {
    apiKey: 'AIzaSyCj9fLIBRyCillM1UHmlKGrFkHiNoitVQQ',
    language: 'en',
    libraries: ['places']
  },
  algolia: {
    config: {
      au: {
        appId: 'EMXOA8KD6A'
      },
      eu: {
        appId: 'EMXOA8KD6A'
      },
      us: {
        appId: 'EMXOA8KD6A'
      }
    } as { [key: string]: { appId: string } }
  },
  api: {
    publicApikey: 'WYwTviXk53pKEJ1ppvaDu5x17MlrpXQO',
    url: 'https://uat-api.roxabo.com'
  },
  appUrls: {
    service: 'https://uat-service.roxabo.com'
  },
  authConfig: {
    clientID: '5bbwynS4BcWw0ohmboApUiNaqStK9EdB',
    domain: 'auth.roxabo.com'
  },
  cookies: {
    domain: 'localhost'
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyBaTqx3aOzDA8AYii8eWDuWWpDlNl38ttE',
      appId: '1:465258667874:web:9b6a4a228ca08c49acd63b',
      authDomain: 'roxabo-uat.firebaseapp.com',
      measurementId: 'G-57Q5XDMY16',
      messagingSenderId: '465258667874',
      projectId: 'roxabo-uat',
      storageBucket: 'roxabo-uat.appspot.com'
    }
  },
  production: true,
  stripe: {
    key: 'pk_test_51HPcgLJcKED04WHAWN1ZNFEQvZRRkyLBE4D46cE8ayoda4qUiBkpEzq4zKBTnIDITIwsx5SrRzoKrDucTahNuskG00BktMwD4D'
  }
};
