import { Component } from '@angular/core';

@Component({
  selector: 'rox-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showLoader = true;
  public headerEnabled = true;

  constructor() {
    if (window.location.pathname.startsWith('/public/')) {
      this.headerEnabled = false;
    }
  }

  public routeActivate(): void {
    this.showLoader = false;
  }
}
