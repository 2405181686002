import { Injectable } from '@angular/core';
import { Tenant } from '@remodzy/types';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';

const regions: { [key: string]: string } = {
  au: 'sydney',
  eu: 'roubaix',
  us: 'texas'
};

@Injectable()
export class TenantsService {
  private currentTenantSubject = new ReplaySubject<Tenant | null>(1);
  public currentTenant$ = this.currentTenantSubject.asObservable();

  constructor(private readonly auth: AuthService, private readonly firestoreService: FirestoreService) {}

  public getCurrent(): Observable<Tenant | null> {
    if (!this.auth.profile?.tenantId) {
      return of(null);
    }
    return this.firestoreService
      .get<Tenant>(appConstants.firebase.collections.tenants, this.auth.profile.tenantId)
      .pipe(tap(res => this.currentTenantSubject.next(res)));
  }

  public update(docId: string, data: Partial<Tenant>, merge: boolean = false): Observable<void> {
    return this.firestoreService.update(appConstants.firebase.collections.tenants, docId, data, merge).pipe(
      switchMap(() => this.currentTenant$),
      take(1),
      tap(res => this.currentTenantSubject.next({ ...res, ...data } as Tenant)),
      mapTo(void 0)
    );
  }

  public getCurrentRegion(): Observable<string> {
    if (!this.auth.profile?.tenantId) {
      return of('');
    }
    return this.firestoreService
      .get<Tenant>(appConstants.firebase.collections.tenants, this.auth.profile.tenantId)
      .pipe(
        map(tenant => {
          const country = tenant?.country || '';
          return regions[country] || country;
        })
      );
  }
}
