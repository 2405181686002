import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RemodzyForm } from '@remodzy/types';
import { GeoPoint } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class PublicService {
  private readonly url = `${environment.api.url}/public`;

  constructor(private http: HttpClient) {}

  public createForm(templateId: string): Observable<string> {
    return this.http
      .post<{ formId: string }>(`${this.url}/forms/${templateId}`, null, {
        headers: {
          apikey: environment.api.publicApikey
        }
      })
      .pipe(map(res => res.formId));
  }

  public getForm(formId: string): Observable<RemodzyForm> {
    return this.http
      .get<RemodzyForm>(`${this.url}/forms/${formId}`, {
        headers: {
          apikey: environment.api.publicApikey
        }
      })
      .pipe(map(res => new RemodzyForm(GeoPoint, res)));
  }

  public updateForm(form: RemodzyForm): Observable<void> {
    return this.http.put<void>(`${this.url}/forms/${form.id}`, form, {
      headers: {
        apikey: environment.api.publicApikey
      }
    });
  }
}
