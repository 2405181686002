import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface GetFormsByDateResponse extends AnalyticsCommonResponse {
  date: {
    value: string;
  };
}

export interface GetFormsByNameThisMonthResponse extends AnalyticsCommonResponse {
  templateFormId: string;
  templateFormName: string;
}

export interface GetFormsByCategoryThisMonthResponse extends AnalyticsCommonResponse {
  categoryId: string;
  categoryName: string;
}

export interface GetUsersThisMonthResponse extends AnalyticsCommonResponse {
  fullName: string;
  id: string;
}

export interface AnalyticsCommonResponse {
  tenantId: string;
  total: number;
}

@Injectable()
export class FormsService {
  private readonly url = `${environment.api.url}/forms`;

  constructor(private readonly http: HttpClient) {}

  public duplicateTemplate(templateId: string): Observable<void> {
    return this.http.get<void>(`${this.url}/template/duplicate/${templateId}`);
  }

  public exportForm(formId: string): Observable<Blob> {
    return this.http.get(`${this.url}/export/${formId}`, {
      responseType: 'blob'
    });
  }

  public getAnalyticsFormsByCategoryThisMonth(): Observable<GetFormsByCategoryThisMonthResponse[]> {
    return this.http.get<GetFormsByCategoryThisMonthResponse[]>(`${this.url}/analytics/getFormsByCategoryThisMonth`);
  }

  public getAnalyticsFormsByDate(): Observable<GetFormsByDateResponse[]> {
    return this.http.get<GetFormsByDateResponse[]>(`${this.url}/analytics/getFormsByDate`);
  }

  public getAnalyticsFormsByNameThisMonth(): Observable<GetFormsByNameThisMonthResponse[]> {
    return this.http.get<GetFormsByNameThisMonthResponse[]>(`${this.url}/analytics/getFormsByNameThisMonth`);
  }

  public getAnalyticsTotalCategories(): Observable<AnalyticsCommonResponse[]> {
    return this.http.get<AnalyticsCommonResponse[]>(`${this.url}/analytics/getTotalCategories`);
  }

  public getAnalyticsTotalForms(): Observable<AnalyticsCommonResponse[]> {
    return this.http.get<AnalyticsCommonResponse[]>(`${this.url}/analytics/getTotalForms`);
  }

  public getAnalyticsTotalUsers(): Observable<AnalyticsCommonResponse[]> {
    return this.http.get<AnalyticsCommonResponse[]>(`${this.url}/analytics/getTotalUsers`);
  }

  public getAnalyticsUsersThisMonth(): Observable<GetUsersThisMonthResponse[]> {
    return this.http.get<GetUsersThisMonthResponse[]>(`${this.url}/analytics/getUsersThisMonth`);
  }
}
