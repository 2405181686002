import { Injectable } from '@angular/core';
import { Lookup } from '@remodzy/types';
import { Observable } from 'rxjs';

import { appConstants } from '../../app.constants';
import { FirestoreService } from './firestore.service';

@Injectable()
export class LookupsService {
  constructor(private readonly firestoreService: FirestoreService) {}

  public getList$(): Observable<Lookup[]> {
    return this.firestoreService.getList$(appConstants.firebase.collections.formLookups);
  }

  public delete(lookupId: string): Observable<void> {
    return this.firestoreService.delete(appConstants.firebase.collections.formLookups, lookupId);
  }

  public update(lookupId: string, lookup: Partial<Lookup>, merge = true): Observable<void> {
    return this.firestoreService.update(appConstants.firebase.collections.formLookups, lookupId, lookup, merge);
  }

  public generateId(): string {
    return this.firestoreService.getRandomId(appConstants.firebase.collections.formLookups);
  }
}
