import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RemodzyPermissions } from '@remodzy/types';

import { PermissionsService } from '../../core/services/permissions.service';

@Directive({
  /* eslint-disable @angular-eslint/directive-selector */
  selector: '[ifPermission]'
})
export class IfPermissionDirective {
  @Input() set ifPermission(permission: RemodzyPermissions) {
    this.updateView(permission);
  }

  private viewRef: EmbeddedViewRef<unknown> | null = null;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private permissionsService: PermissionsService
  ) {}

  private updateView(permission: RemodzyPermissions): void {
    if (this.permissionsService.hasPermission(permission)) {
      if (!this.viewRef) {
        this.viewContainer.clear();
        if (this.templateRef) {
          this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    }
  }
}
