<ngb-toast
  *ngFor="let toast of messages$ | async"
  [autohide]="true"
  class="toast-item"
  [ngClass]="{ 'toast-success': toast.type === 'success', 'toast-error': toast.type === 'error' }"
  [delay]="toast.options.delay!"
  (hidden)="removeMessage(toast.id)">
  <div class="toast-message-wrapper">
    <svg *ngIf="toast.type === 'error'" class="toast-icon">
      <use xlink:href="#toast-error"></use>
    </svg>
    <svg *ngIf="toast.type === 'success'" class="toast-icon">
      <use xlink:href="#toast-success"></use>
    </svg>
    <span class="toast-message">{{ toast.message }}</span>
  </div>
  <div class="toast-progress" [style.animation-duration]="toast.options.delay + 'ms'">&nbsp</div>
</ngb-toast>
