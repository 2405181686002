import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export function filterPredicate<T>(value: T | undefined | null): value is T {
  return !!value;
}

function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function isExist<T>() {
  return (source$: Observable<null | undefined | T>) => source$.pipe(filter(inputIsNotNullOrUndefined));
}
