import { AgmCoreModule } from '@agm/core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie-service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { ApiModule } from './core/api/api.module';
import { AlgoliaService } from './core/services/algolia.service';
import { AuthService } from './core/services/auth.service';
import { CoreServicesModule } from './core/services/core-services.module';
import { PermissionsService } from './core/services/permissions.service';
import { TenantsService } from './core/services/tenant.service';
import { UsersService } from './core/services/users.service';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { RemodzyDirectivesModule } from './shared/directives/remodzy-directives.module';
import { IconsModule } from './shared/icons/icons.module';
import { ToastModule } from './shared/toast/toast.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';

const psConfig: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, NavBarComponent],
  imports: [
    AgmCoreModule.forRoot(environment.agm),
    BrowserAnimationsModule,
    BrowserModule,
    NgbPopoverModule,
    PerfectScrollbarModule,
    provideAuth(() => getAuth()),
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    RemodzyDirectivesModule,
    ToastModule,

    ApiModule,
    AppRoutingModule,
    CoreServicesModule,
    IconsModule,
    HttpClientModule,
    TranslocoRootModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, UsersService, TenantsService, AlgoliaService, PermissionsService, TranslocoService],
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: psConfig
    },
    CookieService,
    provideEnvironmentNgxMask()
  ]
})
export class AppModule {}
