import { NgModule } from '@angular/core';

import { BootstrapValidationDirective } from './bootstrap-validation.directive';
import { CdkDragActualContainerDirective } from './cdk-drag-actual-container.directive';
import { DigitsOnlyDirective } from './digits-only.directive';
import { LaddaDirective } from './ladda.directive';
import { TruncateFilenameDirective } from './truncate-filename.directive';
import { RadioButtonTooltipDirective } from './radio-button-tooltip.directive';
import { IfPermissionDirective } from './if-permission.directive';

@NgModule({
  declarations: [
    BootstrapValidationDirective,
    CdkDragActualContainerDirective,
    DigitsOnlyDirective,
    IfPermissionDirective,
    LaddaDirective,
    TruncateFilenameDirective,
    RadioButtonTooltipDirective
  ],
  exports: [
    BootstrapValidationDirective,
    CdkDragActualContainerDirective,
    DigitsOnlyDirective,
    IfPermissionDirective,
    LaddaDirective,
    TruncateFilenameDirective,
    RadioButtonTooltipDirective
  ]
})
export class RemodzyDirectivesModule {}
