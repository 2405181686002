import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';

import { ApiService } from '../api/api.service';
import { AlgoliaService } from './algolia.service';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { FormControlsService } from './form-controls.service';
import { FormTemplatesService } from './form-templates.service';
import { LookupsService } from './lookups.service';
import { PermissionsService } from './permissions.service';
import { TeamsService } from './teams.service';
import { TenantsService } from './tenant.service';
import { UsersService } from './users.service';

function serviceFactory<T>(Service: new (...args: any[]) => T, ...deps: unknown[]): T | null {
  if (window.location.pathname.startsWith('/public/')) {
    return null;
  }
  return new Service(...deps);
}

@NgModule({
  providers: [
    {
      provide: AlgoliaService,
      useFactory: serviceFactory.bind(null, AlgoliaService),
      deps: [AuthService, FirestoreService, TeamsService]
    },
    {
      provide: AuthService,
      useFactory: serviceFactory.bind(null, AuthService),
      deps: [ApiService, CookieService, Auth]
    },
    {
      provide: FirestoreService,
      useFactory: serviceFactory.bind(null, FirestoreService),
      deps: [AuthService, Firestore]
    },
    {
      provide: FormControlsService,
      useFactory: serviceFactory.bind(null, FormControlsService),
      deps: [FirestoreService]
    },
    {
      provide: FormTemplatesService,
      useFactory: serviceFactory.bind(null, FormTemplatesService),
      deps: [FirestoreService]
    },
    {
      provide: PermissionsService,
      useFactory: serviceFactory.bind(null, PermissionsService),
      deps: [AuthService, FirestoreService]
    },
    {
      provide: LookupsService,
      useFactory: serviceFactory.bind(null, LookupsService),
      deps: [FirestoreService]
    },
    {
      provide: TeamsService,
      useFactory: serviceFactory.bind(null, TeamsService),
      deps: [AuthService, FirestoreService]
    },
    {
      provide: TenantsService,
      useFactory: serviceFactory.bind(null, TenantsService),
      deps: [AuthService, FirestoreService]
    },
    {
      provide: UsersService,
      useFactory: serviceFactory.bind(null, UsersService),
      deps: [FirestoreService, HttpClient, MapsAPILoader, TenantsService]
    }
  ]
})
export class CoreServicesModule {}
