<ng-container *transloco="let t; read: 'navbar'">
  <nav class="navbar">
    <div class="nav-panel">
      <!--    <div [ngbPopover]="popApps"></div> -->
      <ng-template #popApps>
        <div class="apps_menu">
          <div>
            <h5 class="mb-3">{{ t("appsMenu.switchTo") }}:</h5>
          </div>
          <a [href]="serviceUrl" class="apps_menu_link">
            <img class="logo" src="/assets/icons/smallLogoService.svg" alt="Logo" />
            <p class="mb-0">
              {{ t("appsMenu.service") }}
            </p>
          </a>
        </div>
      </ng-template>

      <img class="logo" src="/assets/icons/forms-logo-light.svg" alt="logo" />

      <div class="divider"></div>

      <div class="team" [ngbPopover]="popTeam">
        <div class="team-container">
          <div class="team-logo">
            <img src="/assets/icons/team.svg" alt="team" />
          </div>

          <div class="current-team">
            <ng-container *ngIf="currentTeam; else allTeams">
              <p>{{ currentTeam.location }}</p>
              <p class="current-team-name">{{ currentTeam.name }}</p>
            </ng-container>

            <ng-template #allTeams>
              <p class="current-team-name">{{ t("mainMenu.allTeamsLabel") }}</p>
            </ng-template>
          </div>
        </div>

        <button type="button" class="btn-icon-no-bg btn-nav-bar">
          <svg>
            <use xlink:href="#chevron-down"></use>
          </svg>
        </button>
      </div>

      <ng-template #popTeam>
        <ul class="team-menu-selector" [perfectScrollbar]>
          <li class="team-location">
            <button type="button" class="btn location-name" [class.active]="!currentTeam" (click)="selectTeam(null)">
              {{ t("mainMenu.allTeamsButton") }}
            </button>
          </li>

          <li class="team-location" *ngFor="let location of locations">
            <p class="location-name">{{ location.location }}</p>

            <button
              type="button"
              class="btn team-name"
              [class.active]="currentTeam === team"
              *ngFor="let team of location.teams"
              (click)="selectTeam(team)">
              {{ team.name }}
            </button>
          </li>
        </ul>
      </ng-template>

      <div class="divider"></div>

      <div class="main-menu">
        <a routerLink="forms" routerLinkActive="active" *ifPermission="permissions.Forms" class="main-menu-link">
          {{ t("mainMenu.forms") }}
        </a>

        <a
          routerLink="form-builder"
          routerLinkActive="active"
          *ifPermission="permissions.Templates"
          class="main-menu-link">
          {{ t("mainMenu.templates") }}
        </a>

        <a
          routerLink="analytics"
          routerLinkActive="active"
          *ifPermission="permissions.Analytics"
          class="main-menu-link">
          {{ t("mainMenu.analytics") }}
        </a>

        <a
          routerLink="messaging"
          routerLinkActive="active"
          *ifPermission="permissions.Messaging"
          class="main-menu-link">
          {{ t("mainMenu.messaging") }}
        </a>

        <a routerLink="staff" routerLinkActive="active" *ngIf="isAdmin" class="main-menu-link">
          {{ t("mainMenu.staff") }}
        </a>
      </div>
    </div>

    <div class="nav-panel">
      <div class="profile">
        <p class="profile-name">{{ fullName }}</p>

        <div class="profile-logo">{{ initials }}</div>

        <button type="button" class="btn btn-icon-no-bg btn-nav-bar" [ngbPopover]="popProfile" placement="bottom-right">
          <svg>
            <use class="chevron-icon" xlink:href="#chevron-down"></use>
            <use class="menu-icon" xlink:href="#menu"></use>
          </svg>
        </button>
      </div>

      <ng-template #popProfile>
        <div class="profile-menu">
          <div class="profile">
            <div class="profile-logo">{{ initials }}</div>
            <p class="profile-name">{{ fullName }}</p>
          </div>

          <a routerLink="/settings/profile" class="main-menu-link" routerLinkActive="active">
            {{ t("profileMenu.myProfile") }}
          </a>

          <a
            routerLink="/settings/business"
            *ifPermission="permissions.Settings"
            class="main-menu-link"
            routerLinkActive="active">
            {{ t("profileMenu.settings") }}
          </a>

          <button type="button" class="btn main-menu-link" (click)="logout()">
            {{ t("profileMenu.logOut") }}
          </button>
        </div>
      </ng-template>
    </div>
  </nav>
</ng-container>
